import React from 'react'
import { PageProps } from 'gatsby'
import AuthorizeCustomerService from '../components/Authentication/authorize-customer-service'
import AuthorizeMember from '../components/Authentication/authorize-member'
import { trackError } from '~/analytics/errors'

export default function HandleAuth0Callback({ location }: PageProps) {
  const isCustomerService =
    location?.search?.indexOf('Admin-Authentication') !== -1
  if (isCustomerService) {
    trackError((scope) => {
      scope.setContext('search', window.btoa(location.search))
      scope.capture(new Error('Customer Service Login'))
    })
    return <AuthorizeCustomerService location={location} />
  } else {
    return <AuthorizeMember location={location} />
  }
}
