import React from 'react'
import { navigate, PageProps } from 'gatsby'
import { useAuth0 } from '@auth0/auth0-react'
import { parse } from 'querystringify'
import { trackSignIn } from '~/analytics/events'
import { segmentIdentify } from '~/analytics/identifiers'
import { getUserIdFromAuth0User, login } from '~/utils/auth0'

/**
 * This path is triggered by auth0 at the end of the login flow. Look for
 * any desired redirects and perform them, or fall back to redirecting
 * to the member page.
 */

export default function AuthorizeMember({
  location,
}: Pick<PageProps, 'location'>) {
  /**
   * On first load, `isAuthenticated` is false. But quickly flips to true
   * once the Auth0 provider boots up.
   */

  const { error, isAuthenticated, isLoading, user } = useAuth0()

  React.useEffect(() => {
    if (isLoading) return

    if (isAuthenticated && user) {
      const params = parse(location.search) as {
        redirect?: string
      }

      // do a lightweight identify so the sign in event is captured before
      // full identification later when the user is loaded
      // This uses the email address associated with the user's auth0 JWT
      segmentIdentify({
        userId: getUserIdFromAuth0User(user),
        traits: { email: user.email },
      })

      trackSignIn(user.email)

      navigate(params.redirect || 'https://member.butcherbox.com/member')
    } else if (error) {
      // customer declined oidc consent
      navigate('/')
    } else {
      login()
    }
  }, [error, isAuthenticated, isLoading, location.search, user])

  return <span />
}
