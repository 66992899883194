import React from 'react'
import { navigate } from 'gatsby'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { parse } from 'querystringify'
import { trackSignIn, trackSignInImpersonated } from '~/analytics/events'
import { segmentIdentify } from '~/analytics/identifiers'
import { getUserIdFromAuth0User, login } from '~/utils/auth0'
import { GET_CUSTOMER } from '~/bb-api/endpoints'
import { API, CACHE_KEY_CUSTOMER } from '~/bb-api/constants'
import axios from '~/utils/axios'
import { User } from '~/bb-api/schemata'
import { WindowLocation } from '@reach/router'

interface IAuthorizeProps {
  location: WindowLocation
}

/**
 * This path is triggered by auth0 at the end of the login flow. Look for
 * any desired redirects and perform them, or fall back to redirecting
 * to the member page.
 */
export default function AuthorizeCustomerService({
  location,
}: IAuthorizeProps) {
  /**
   * On first load, `isAuthenticated` is false. But quickly flips to true
   * once the Auth0 provider boots up.
   */
  const { error, isAuthenticated, isLoading, user: authUser } = useAuth0()

  /**
   * On valid user, get the customer URL eg: /api/v4/customer/123
   */
  const url = React.useMemo(() => {
    if (authUser) {
      const uid = getUserIdFromAuth0User(authUser)
      return uid ? GET_CUSTOMER(parseInt(uid, 10), API.V4) : ''
    }
    return ''
  }, [authUser])

  /**
   * Query the BB API to get the user object
   */
  const {
    data: user,
    isLoading: userQueryIsLoading,
    error: userQueryError,
  } = useQuery(
    CACHE_KEY_CUSTOMER,
    () => {
      return axios.get<User>(url).then((res) => res.data)
    },
    { enabled: !!url, staleTime: Infinity }
  )

  React.useEffect(() => {
    if (isLoading || userQueryIsLoading) return

    if (isAuthenticated && authUser && user) {
      const params = parse(location.search) as {
        redirect?: string
      }

      segmentIdentify({ traits: { email: user.emailAddress } })

      trackSignIn(user.emailAddress)

      trackSignInImpersonated(authUser.email)

      navigate(params.redirect || 'https://member.butcherbox.com/member')
    } else if (error || userQueryError) {
      // customer declined oidc consent
      navigate('/')
    } else if (!isAuthenticated && !authUser && !user) {
      login()
    }
  }, [
    error,
    isAuthenticated,
    isLoading,
    location.search,
    authUser,
    user,
    userQueryIsLoading,
    userQueryError,
  ])

  return <span />
}
